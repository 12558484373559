import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import firebase from 'firebase/compat/app';
import "firebase/compat/storage";
import { useNavigate } from 'react-router-dom';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2lsbGlhbWQ0NyIsImEiOiJja3UxNWhtZjYwMmI0Mm9vNjFwY3RkZjFnIn0.WY_oY_c_zYjZtJi0h4VRcw';


const firebaseConfig = {
    apiKey: "AIzaSyApSxy34buqbwXqaEMaRffU3rK818l_bps",
    authDomain: "playgroundfinder-64335.firebaseapp.com",
    projectId: "playgroundfinder-64335",
    storageBucket: "playgroundfinder-64335.appspot.com",
    messagingSenderId: "228904073713",
    appId: "1:228904073713:web:b146b4bdb71ee503efec50",
    measurementId: "G-FDZ01FPSZC"
};

firebase.initializeApp(firebaseConfig);

const darkStyle = 'mapbox://styles/williamd47/ckyyruv6y002m15p1cun7s9a0'
export default function Map() {
    const navigator = useNavigate();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-3.2);
    const [lat, setLat] = useState(53.8423);
    const [zoom, setZoom] = useState(5);
    useEffect(() => {

        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: darkStyle,
            center: [lng, lat],
            zoom: zoom
        });
        // playgrounds = GetPlaygrounds();

    });
    
    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
        
        map.current.on('click', 'finisheddata-1nsqts', (e) => {
            const description = e.features[0].properties.description;
            navigator('/playground/' + description, { replace: true });

        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.current.on('mouseenter', 'finisheddata-1nsqts', () => {
            map.current.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.current.on('mouseleave', 'finisheddata-1nsqts', () => {
            map.current.getCanvas().style.cursor = '';
        });

    });
    return (
        <div>
            <div className="searchBar">
                <div className="searchBar-bottom">
                    Longitude: {lng} | Latitude: {lat}
                </div>
            </div>
            <div ref={mapContainer} className="map-container" />
        </div>
    )
}
