import { useParams } from "react-router-dom"
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import Map from "./Minimap";
import Rating from "./Rating";
import { Button, MantineProvider } from '@mantine/core'
import { FiExternalLink } from 'react-icons/fi';
import { BiExit } from 'react-icons/bi';

const firebaseConfig = {
    apiKey: "AIzaSyApSxy34buqbwXqaEMaRffU3rK818l_bps",
    authDomain: "playgroundfinder-64335.firebaseapp.com",
    projectId: "playgroundfinder-64335",
    storageBucket: "playgroundfinder-64335.appspot.com",
    messagingSenderId: "228904073713",
    appId: "1:228904073713:web:b146b4bdb71ee503efec50",
    measurementId: "G-FDZ01FPSZC"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function PlaygroundViewer() {
    const { name } = useParams();
    let playgroundError = false;
    const [playgroundData, setPlaygroundData] = useState({
        name: "",
        slug: "",
        rating: 5,
        description: "",
        lat: 0,
        long: 0
    })
    async function getData() {
        const docRef = doc(db, "playgrounds", name)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setPlaygroundData({
                name: docSnap.data().name,
                description: docSnap.data().description,
                lat: docSnap.data().lat,
                long: docSnap.data().long,
                rating: docSnap.data().rating,
                slug: docSnap.data().slug
            })

        } else {
            playgroundError = true
        }
    }
    useEffect(() => {
        getData()
        console.log(playgroundData.lat, playgroundData.long)
    }, [])
    return (
        <MantineProvider theme={{ colorScheme: 'dark' }}>
            <Button
                component="a"
                href={'../../'}
                variant="outline"
                color="white"
                leftIcon={<BiExit size={14} />}
                style={{ fontFamily: 'monospace', marginLeft: '10px', marginTop: '10px', position: 'absolute', left: 0, top: 0 }}>
                Back to Map
            </Button>
            {playgroundError ? <p>That is not a real playground</p> :
                <div className="centerBox">
                    <div className="text">
                        <h3>{playgroundData.name}</h3>
                        <p className="infoText">
                            {playgroundData.description}
                        </p>
                        <div className="nav-rat">
                            <Rating rating={playgroundData.rating} />
                            <Button
                                component="a"
                                target="_blank"
                                href={'https://maps.google.com/?q=' + String(playgroundData.lat) + ',' + String(playgroundData.long)}
                                variant="outline"
                                color="white"
                                leftIcon={<FiExternalLink size={14} />}
                                style={{ fontFamily: 'monospace', marginLeft: '30px' }}>
                                Navigate
                            </Button>
                        </div>
                    </div>

                    <Map lat={playgroundData.lat} long={playgroundData.long} />
                </div>
            }
        </MantineProvider>

    )
}
