import { GoStar } from 'react-icons/go'

export default function Rating(props) {
    return (
        <div className="star-rating">
            {[...Array(props.rating)].map((star) => {
                return (
                    <GoStar color="gold" size="38px"/>);
            })}
            {[...Array(5 - props.rating)].map((star) => {
                return (
                    <GoStar color="gray" size="38px"/>);
            })}
        </div>

    )
}

