import mapboxgl from '!mapbox-gl';// eslint-disable-line import/no-webpack-loader-syntax
import { useState, useRef, useEffect } from 'react';
mapboxgl.accessToken = 'pk.eyJ1Ijoid2lsbGlhbWQ0NyIsImEiOiJja3UxNWhtZjYwMmI0Mm9vNjFwY3RkZjFnIn0.WY_oY_c_zYjZtJi0h4VRcw';


const darkStyle = 'mapbox://styles/williamd47/cl0o9r3at002715npmhepfxyr'
export default function Map(props) {
    console.log(Number(props.long), Number(props.lat))
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-3.2);
    const [lat, setLat] = useState(53.8423);
    const [zoom, setZoom] = useState(5);
    useEffect(() => {

        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: darkStyle,
            center: [lng, lat],
            zoom: zoom
        });
        map.current.on('load', () => {
            // Add an image to use as a custom marker
            map.current.loadImage(
                'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                (error, image) => {
                    if (error) throw error;
                    map.current.addImage('custom_marker', image);
                    // Add a GeoJSON source with 2 points
                    map.current.addSource('singlepoint', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    // feature for Mapbox DC
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': [
                                            Number(props.long), Number(props.lat)
                                        ]
                                    },
                                    'properties': {
                                        'title': 'Point'
                                    }
                                }
                            ]
                        }
                    });

                    // Add a symbol layer
                    map.current.addLayer({
                        'id': 'singlepoint',
                        'type': 'symbol',
                        'source': 'singlepoint',
                        'layout': {
                            'icon-image': 'custom_marker',
                        }
                    });
                }
            );
        });

    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
        
    });
    
    return (
        <div>
            <div ref={mapContainer} className="mini-map-container" />
        </div>
    )
}