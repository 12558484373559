import './App.css';
import React from 'react';
import Map from './Map'
import PlaygroundViewer from './components/PlaygroundViewer';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

function App() {
  
  return (
    <BrowserRouter>
      <div>
        
      </div>
      <div>
        <Routes>
          <Route path="/playground/:name" element={<PlaygroundViewer />} />
          <Route path="/" element={<Map/>} />
        </Routes>
      </div>
    </BrowserRouter>
    
  );
}

export default App;
